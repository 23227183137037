.notification {
    position: fixed;
    font-size: medium;
    z-index: 1100 !important;
    text-align: center;
    top: 0;
    padding: 1em;
    width: 100%;
    min-height: .5em;
    opacity: 1;
    /*  add 100ms extra to animation time than in notification reducers timeout
        because reducers timeout should run out before this */
    animation: slide 10100ms;
    /*  if animation time is changed, change in notification-reducer accordingly */
}

.notification-btn {
    position: fixed;
    color: #313233;
    right: 0;
    z-index: 1101;
    top: 0.5em;
}

.success {
    background-color: #70aa6d;
    color: #e8e8e8;
}

.failure {
    background-color: #f6653c;
    color: #e8e8e8;
}

.warning {
    background-color: #edc974;
    color: #313233;
}

@keyframes slide {
    0% {
        transform: translate(0%, -100%);
    }

    10% {
        transform: translate(0%, 0%);
    }

    90% {
        transform: translate(0%, 0%);
    }

    100% {
        transform: translate(0%, -100%);
    }
}