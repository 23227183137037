.worklog-tab-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-template-rows: 2;
    gap: 1em;
    padding-bottom: 1em;
    user-select: none;
}

.worklog-tab {
    border: 1px solid var(--borders-login-bg);
    border-radius: .5em;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.worklog-selected {
    background-color: var(--main-orange);
    color: white;
}