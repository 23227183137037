.map-modal-size {
    width: 100%;
    height: 50vh;
}

@media only screen and (max-width: 800px) {
    .map-modal-size {
        width: 100%;
        height: 70vh;
    }
}