.order-desc {
    width: 100%;
}

.order-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.order-info-item {
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    align-items: center !important;
}

.orange-white-btn-get-latest {
    color: #fff !important;
    background-color: var(--main-orange) !important;
    padding: 1.4em !important;
    margin: 0 !important;
    margin-bottom: 1em !important;
    margin-left: 1em !important;
}

.order-top-controls {
    display: flex;
}