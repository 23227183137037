.selected {
    background-color: var(--main-orange);
    cursor: pointer;
}

.menu-item {
    flex: 1;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgb(255, 255, 255);
    position: absolute;
    top: 100%;
    transform: translateY(-10px);
}

/* SMALL SCREEN */

@media only screen and (max-width: 800px) {
    .arrow-up {
        display: none;
    }

    .menu-item {
        padding: 1em;
        justify-content: flex-start !important;
        border-top: 1px solid var(--borders-login-bg);
    }
}