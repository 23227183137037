.time {
    width: 3em;
    text-align: center;
    border: var(--borders);
    border-radius: .5em;
    margin-bottom: .2em;
    margin-top: .2em;
    padding: .5em;
    cursor: pointer;
    font-size: larger;
}

.time::-webkit-outer-spin-button,
.time::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.time:focus {
    outline: none !important;
    border: var(--borders) !important;
}

.timeselect {
    text-align: center;
}

.timeselect-icon {
    width: 100%;
    color: var(--menu-footer-bg);
}

.timeselect-icon>* {
    margin: 0 !important;
    width: 100% !important;
}

.timepicker {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 35%;
    justify-content: space-evenly;
}