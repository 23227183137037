.modal-delete-popup {
    top: 37.5vh;
}

.modal-header {
    margin: 0 !important;
    padding: 1.5em !important;
    background-color: var(--main-orange) !important;
    display: flex !important;
    justify-content: space-between !important;
}

.modal-btn {
    background-color: var(--main-orange);
    color: #fff;
}


.modal-buttons {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, 110px);
    grid-template-rows: 2;
}

.modal-btn:hover {
    color: var(--main-orange);
    background-color: #fff;
}