.grid-footer {
    grid-area: footer;
    background-color: #444;
}

.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
}

.side-left {
    grid-area: side-left;
    background-color: #444;
}

.side-right {
    grid-area: side-right;
    background-color: #444;
}

.yt-btn {
    border: .15em solid var(--darker-background-color);
    border-radius: .5em;
    padding: .2em;
    margin: .1em;
    margin-left: 1em;
    user-select: none;
}

.yt-btn:hover {
    background-color: var(--main-orange);
}

/* WIDE SCREEN */

@media only screen and (min-width: 1190px) {
    .side-left {
        grid-area: side-left !important;
        background-color: #444 !important;
    }

    .side-right {
        grid-area: side-right !important;
        background-color: #444 !important;
    }
}

/* MEDIUM SCREEN */

@media only screen and (max-width:1190px) {
    .side-left {
        display: none;
    }

    .side-right {
        display: none;
    }
}