:root {
    --header-bg: #ffffff;
    --page-bg: #313233;
    --borders-login-bg: #ecebe9;
    --borders: 1px solid #ecebe9;
    --icons: #aeaeae;
    --darker-background-color: #f5f5f5;
    --menu-footer-bg: #444444;
    --text-primary: #313233;
    --main-orange: #f6653c;
}

/* MARANET 2.0 */

body {
    /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
    background-color: var(--darker-background-color);
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #313233;
}

h1 {
    font-size: 26pt;
    font-weight: 400;
}

h2 {
    font-size: 22pt;
    font-weight: 400;
}

h3 {
    font-weight: 700;
}

p {
    font-size: 12pt;
    font-weight: 400;
}

a {
    color: var(--main-orange);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body::-webkit-scrollbar {
    width: .5rem;
}

body::-webkit-scrollbar-track {
    background: var(--icons);
}

body::-webkit-scrollbar-thumb {
    background: var(--menu-footer-bg);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/* MISC CSS */

/* REGISTERING */

.register-steps {
    display: flex;
    flex-direction: row;
}

.register-steps>div {
    margin: 0;
    padding: 0;
    flex: 1;
    border-top: .3rem rgba(128, 128, 128, 0.5) solid;
}

.register-steps>div:hover {
    background-color: rgba(240, 240, 240, 0.5);
    cursor: pointer;
    user-select: none;
}

.register-steps>div>a {
    color: rgba(128, 128, 128, 0.5);
    padding-left: 35%;
    padding-right: 35%;
}

.register-steps>div.active {
    border-top: .3rem black solid;
}

.register-steps>div.active>a {
    color: black;
}

.removable-item {
    padding: .7em;
    margin: .2rem;
    border: rgba(21, 21, 21, 0.042) 1px solid;
    border-radius: 1rem;
}

.removable-item-placeholder {
    padding: .7em;
    margin: .2rem;
}

/* LOGIN */

.login-bg {
    background-color: var(--borders-login-bg);
}

.login-spacer {
    padding-bottom: 1em !important;
}

.login-btn {
    background-color: var(--main-orange) !important;
    color: #ffffff !important;
}

.login-logo {
    max-height: 3em;
}

.login-links {
    text-align: left;
}

.login-links>div {
    padding-bottom: .5em;
}

.login-reset-text {
    padding-bottom: 1em;
}

/* DEFAULT CONTENT  */

html {
    scroll-behavior: smooth;
}

/* DEFAULT BUTTONS */

.clear-dark-btn {
    color: #444444;
}

.clear-dark-btn:hover {
    background-color: var(--main-orange);
    color: #fff;
}

.orange-white-btn {
    color: #fff !important;
    background-color: var(--main-orange) !important;
    margin: .7em !important;
    padding: .7em !important;
}

.orange-white-btn:hover {
    background-color: var(--darker-background-color) !important;
    color: var(--main-orange) !important;
}

.sticky-btn.ui.button {
    color: #fff;
    background-color: var(--main-orange);
    padding: .7em 2em .7em 2em;
}

/* DEFAULT FLEX */

.flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-self: center;
}

.column-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.hover:hover {
    background-color: var(--darker-background-color) !important;
    cursor: pointer !important;
    transition: background-color 200ms linear;
}

.margin-top {
    margin-top: 1em;
}

/* BASE LAYOUT */

.user-div {
    padding: .5em;
    border-radius: 5px;
}

.user-icon {
    display: inline-block;
}

.menu-text {
    color: #fff;
    font-weight: 500;
    font-size: 15pt;
}

.webview-input {
    height: 40px;
    width: 200px;
}

.main-text {
    color: #444;
    font-weight: 400;
    font-size: 15pt;
}

.grid-main {
    grid-area: main;
    background-color: #fff;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.15);
}



.menu-item:hover {
    background-color: var(--main-orange);
    cursor: pointer;
    transition: background-color 200ms linear;
}

.main-row-with-icons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-gap: 1em;
    grid-auto-rows: minmax(130px, 1fr);
    align-items: center;
    text-align: center;
    margin: 1em;
}

.main-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-gap: .5em;
    align-items: center;
    text-align: center;
    margin: .5em;
}

.main-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding: 1em;
    cursor: pointer;
}

.tab-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding: 1em;
    cursor: pointer;
}

.tab-selected {
    background-color: var(--main-orange);
    color: #fff;
}

.main-selected {
    color: var(--main-orange);
}

.main-selected>img {
    filter: none !important;
}

.main-image {
    height: 3em;
    filter: brightness(20%) sepia(1);
    margin-bottom: 1em;
}

.main-content {
    width: 100%;
    border-top: .15em solid var(--darker-background-color);
    padding: 2em;
    padding-left: 3em;
    padding-right: 3em;
}

.selector-grid {
    display: grid;
    column-gap: 1em;
    row-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: minmax(150px, 1fr);
}

.selector-box {
    padding: 1em;
    border: .1em solid #ecebe9;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    user-select: none;
}

.selector-modal.ui.modal {
    position: relative;
    width: auto;
    padding: 2em;
}

.selector-selected {
    background-color: var(--darker-background-color);
}

.split-div {
    padding-top: .5em;
    width: 100%;
    display: flex !important;
    /* flex: 4; */
    flex-direction: row !important;
    align-items: center;
    justify-content: space-around !important;
}

.split-div-or {
    flex: 1;
    transform: translate(20%);
    height: 100%;
    border-left: 1px solid var(--borders-login-bg);
}

.split-div-second {
    margin-left: .25em !important;
    flex: 1 1 60% !important;
}

.selector-btn {
    color: #fff !important;
    background-color: var(--main-orange) !important;
    margin: 0 !important;
    padding: .825em !important;
    text-align: center;
}

.show-more-btn {
    align-self: stretch;
    text-align: center;
}

.date-modal {
    width: auto !important;
}

.content-loader {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
}

.ui.compact.dropdown {
    min-height: auto;
}

/* KIRJAUSTIEDOT, RYHMÄT JA KÄYTTÄJÄT */

.add-values-container {
    max-width: 100%;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(2, 1fr);
}

.add-values-form {
    background-color: var(--darker-background-color);
    padding: 1.3em;
    height: fit-content;
}

.no-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.no-margin-left {
    margin-left: 0 !important;
}

.scrollable-list {
    overflow-y: scroll;
    max-height: 540px;
    width: 100%;
}

/* RAPORTIT */

.reports-description {
    margin-bottom: 2em;
}

.reports-container {
    width: 80%;
    margin: auto;
    /* padding: 1em; */
    border: 1px solid var(--borders-login-bg);
    box-shadow: rgba(99, 99, 99, 0.2) 2px 2px 8px 0px;
}

.reports-container>.selector-grid-reports:last-child {
    border: none;
}

.ui.search.dropdown>.text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    white-space: nowrap;
}

.reports-checkbox-container {
    display: grid;
    max-width: 100%;
    grid: auto / 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    align-content: center !important;
    justify-content: space-evenly;
}

.reports-checkbox {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
}

.reports-select {
    padding: 1em;
    grid-column: span 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.reports-checkbox-container ul li {
    display: inline-block;
    vertical-align: middle;
}

.rpttext {
    width: 100%;
}

.rptbox {
    width: 22%;
}

.reports-selector-header {
    border-right: 1px solid var(--borders-login-bg);
}

.selector-grid-reports {
    display: grid;
    grid-template-columns: 30% repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: minmax(75px, 1fr);
    border-bottom: 1px solid var(--borders-login-bg);
}

.reports-selector-box {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    user-select: none;
}

.selector-box-dl-btn {
    padding: 1em;
    border: 0em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    user-select: none;
}

.radio-btn {
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.reports-add-conditions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pdf-report {
    width: 100%;
}

/* RYHMÄT */

.grouptable-selectgroups {
    color: var(--main-orange);
    padding: 1em;
    margin-top: 1em;
    border-radius: 1em;
    width: fit-content;
    cursor: pointer;
}

.grouptable-modal {
    max-width: 500px;
}

.grouptable-selectgroups:hover {
    color: white;
    background-color: var(--main-orange);
}

.grouptable-list {
    list-style-type: none;
    border: 1px solid var(--borders-login-bg);
    border-radius: .5em;
    margin: 1em;
    margin-top: 0;
    padding: 0;
    overflow-y: scroll;
    user-select: none;
    max-height: 250px;
}

.grouptable-list::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
}

.grouptable-selector-boxes {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    user-select: none;
}

.grouptable-selector-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.grouptable-selector-boxes>div {
    flex: 1;
    min-width: 200px;
}

.subgroup-li {
    padding: 1em;
    padding-left: 2em;
    cursor: pointer;
    display: flex;
    color: var(--main-orange);
    width: fit-content;
}

.group-information-groups {
    margin-top: 1em;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-y: scroll;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

.group-item {
    color: var(--main-orange);
    padding: .5em;
    margin: .5em;
    height: fit-content;
    text-align: center;
    border-radius: 1em;
    user-select: none;
    cursor: pointer;
}

.group-item:hover {
    background-color: var(--main-orange);
    color: #fff;
}

.removable-group {
    border: 1px solid var(--borders-login-bg);
    height: fit-content;
}

.no-groups {
    text-align: center;
    padding: 1em;
}

.group-selector-box {
    padding-bottom: 1em;
    margin-top: 1em;
    padding-top: 1em;
    border: .1em solid #ecebe9;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    user-select: none;
}

.center-group-text {
    text-align: center;
}

.top-pad {
    padding-top: 3em;
}

.defaultGroup-bold {
    font-weight: bold;
}

/* TARKASTUS */

.check-description {
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.check-input {
    background-color: var(--main-orange);
    width: 50px;
    height: 50px;
    cursor: grab;
    user-select: none;
}

.draggable-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: center;
}

.draggable-li {
    text-align: center;
    display: flex;
    align-items: center;
    cursor: move;
    line-height: 1;
    margin-bottom: 0.5em;
    border-radius: 5px;
    border: 1px solid var(--borders-login-bg);
    background-color: #f1f1f1;
}

.draggable-li.dropArea {
    color: white !important;
    background: white !important;
    position: relative;
}

.dropArea::before {
    content: "Siirrä tähän";
    color: var(--text-primary);
    font-size: 0.5em;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    border: 2px dashed var(--darker-background-color);
    border-radius: 3px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checklist-add-inputs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    grid-template-rows: auto;
    gap: .5em;
    padding: 1em;
}

.checklist-box {
    padding: 1em;
    margin-left: .5em;
    width: 100%;
    display: flex !important;
    flex: 4;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between !important;
}

.checklist-description {
    flex: 0 0 40% !important;
}

.checklist-type {
    flex: 0 0 30% !important;
}

.checklist-controls {
    flex: 0 0 20% !important;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

.checklist-level {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 1em;
}

.sub-select {
    animation: expand 500ms !important;
}

.checklist-header {
    margin-top: .5em;
    padding-bottom: .5em;
    border-bottom: 1px solid var(--borders-login-bg);
}

.hide-in-mobile-cut-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cut-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.3em !important;
}

@keyframes expand {
    0% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

/* MAP */

.map-btn {
    margin-left: -1em;
}

/* MEDIA  */

.ui.disabled.input {
    opacity: 1;
}

.ui.inverted.dimmer .ui.loader {
    top: 50%;
    position: fixed;
}

/* WIDE SCREEN */

@media only screen and (min-width: 1190px) {
    .container {
        display: grid !important;
        grid-template-columns: auto 70vw auto !important;
        grid-template-rows: 7em 5em 4em 1fr 5em !important;
        min-height: 100vh !important;
        grid-template-areas: "header header header" "menubar menubar menubar" "side-left main side-right" ". main ." ". footer ." !important;
    }

    .modal-exit-btn {
        display: block;
    }

    .fileselector-modal-size.ui.modal {
        max-width: 70vw;
        padding-bottom: 0;
        will-change: unset !important;
    }
}

/* MEDIUM SCREEN */

@media only screen and (max-width:1190px) {
    .container {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 7em 5em 1fr 5em;
        min-height: 100vh;
        grid-template-areas: "header" "menubar" "main" "footer";
    }

    .fileselector-modal-size.ui.modal {
        max-width: 70vw;
        padding-bottom: 0;
    }
}

/* SMALL SCREEN */

@media only screen and (max-width: 800px) {
    .grid-main {
        box-shadow: none;
    }

    .ui.modal>.close {
        left: 1.5rem !important;
    }

    .container {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 7em auto 1fr 5em;
        min-height: 100vh;
        grid-template-areas: "header" "menubar" "main" "footer";
    }

    .hide-in-mobile {
        display: none;
    }

    .hide-in-mobile-cut-text {
        display: none;
    }

    .add-values-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .main-content {
        padding: 1em;
    }

    .main-image {
        filter: brightness(1000%) !important;
    }

    .main-selected {
        background-color: #f6643caf !important;
        filter: brightness(120%) !important;
    }

    .main-selected>img {
        filter: brightness(1000%) !important;
    }

    .hover:hover {
        background-color: var(--main-orange) !important;
        opacity: .6;
    }

    .main-item {
        background-color: var(--main-orange);
        color: #fff;
    }

    .tab-item {
        border: 1px solid var(--borders-login-bg);
    }

    .reports-container {
        width: 100%;
    }

    .reports-checkbox {
        margin: 0;
    }

    .fileselector-modal-size.ui.modal {
        width: auto;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 600px) {
    .hide-in-mobile-tight {
        display: none;
    }
}