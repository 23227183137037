.grouplist-title {
    padding: 1em;
    padding-left: 0;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.grouplist-title>* {
    margin: 0;
    padding-top: .5em;
}


.grouplist {
    list-style-type: none;
    border: 1px solid var(--borders-login-bg);
    border-radius: .5em;
    margin-bottom: 1em;
    padding: 0;
    overflow-y: scroll;
    user-select: none;
    max-height: 250px;
    box-shadow: rgba(99, 99, 99, 0.2) 2px 2px 8px 0px;
}

.grouplist::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
}