.personal-information-modal {
    width: 100%;
    padding: 2em;
    padding-left: 3em;
    padding-right: 3em;
}

.profile-information {
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: var(--borders);
    border-bottom: var(--borders);
    margin-bottom: 1em;
}

.personal-information-add-values {
    background-color: var(--darker-background-color);
    padding: 2em;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}