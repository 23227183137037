.quantity-selector {
    align-self: stretch;
}

.quantity-selector-label {
    font-size: 1em !important;
    display: grid !important;
    align-content: center;
    border-color: #85b7d9 !important;
    margin-left: .15em;
}