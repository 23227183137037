.file-image-preview {
    position: relative;
    display: grid;
    place-items: center;
}

.file-delete-btn.ui.button {
    color: #fff;
    border-radius: .3em;
    background-color: var(--main-orange);
    position: absolute;
    right: .3em;
    margin: 0;
}

.file-selector-footer {
    border-radius: 0 !important;
    position: sticky;
    bottom: -14px;
    background-color: white;
    padding: 16px 0 14px 0;
    display: flex;
    justify-content: center;
}

.fileSelector-loader.ui.active {
    margin: auto;
}

.file-download-button.ui.button {
    color: #fff;
    border-radius: .3em;
    background-color: var(--main-orange);
    position: absolute !important;
    left: .3em;
    margin: 0;
}