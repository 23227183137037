.selector-segment {
    min-width: 250px;
    width: 75vmin;
    max-width: 25em;
    height: 30em !important;
}

.vertical-padding {
    padding-top: 1em;
    padding-bottom: 1em;
}

.search-list-results {
    margin-top: 1em;
    flex: 1;
    height: 30em;
    align-self: stretch;
    overflow-y: scroll;
}

.ui.search .prompt {
    border-radius: 0.5em;
}